import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllProperties extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`CategoryProperty/GetAll?pageNumber=${data.pageNumber}&count=${data.count}&searchCommand=${data.search}`);
    }
}

class DeleteService extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`Service/Delete?id=${data.id}`);
    }
}


class UpdateService extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Service/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateService extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Service/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetServiceForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Service/GetServicesForVendor');
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetServiceById extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Service/Get?serviceId=${data.serviceId}`);
    }

}
class GetServiceByIdForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
    }
    setParams(data) {
        super.setTag(`Service/GetForVendor?productId=${data.serviceId}`);
    }

}
class UpdateServicePrice extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServicePrice/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class ServiceCategoryGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ServiceCategory/GetAll')
    }
}
class CopyServiceObjects extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Service/CopyObject')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeIsInStock extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Service/ChangeStock')
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}
class ChangeServiceStatus extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Service/ChangeStatus')
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    GetAllProperties,
    CopyServiceObjects,
    ChangeIsInStock,
    GetServiceByIdForVendor,
    ChangeServiceStatus,
    GetServiceForVendor,
    GetServiceById,
    DeleteService,
    UpdateService,
    UpdateServicePrice,
    CreateService,
    ServiceCategoryGetAllRequest
};
