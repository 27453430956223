import {MediaCreateFileRequest} from "@/libs/Api/Media";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";

export default class UploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    async sendCreateFileRequest(base64) {
        let result = ''
        try {
            let _this = this;
            let createFile = new MediaCreateFileRequest(_this);
            createFile.setParams({
                base64: base64,
                objectId: 0,
                type: 9,
                priority: null,
            });
            await createFile.fetch((response) => {
                if (response.data.isSuccess) {
                    result = response.data.imageName
                }
            });
        } catch (error) {
            console.error(error);
        }
        return result
    }

    // Starts the upload process.
   async  upload() {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.addEventListener("load", async () => {
                let pictureUrl = await this.sendCreateFileRequest(reader.result)
                let result = Helper.baseUrl + 'Media/Gallery/Libraries/' + pictureUrl
                resolve({default: result});
            });
            reader.addEventListener("error", (err) => {
                reject(err);
            });

            reader.addEventListener("abort", () => {
                reject();
            });

            this.loader.file.then((file) => {
                reader.readAsDataURL(file);
            });
        });
    }

    // Aborts the upload process.
    abort() {
        //
    }
}
