import WebServiceRequest from '../Api/WebServiceRequest'

class CreateBrand extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Brand/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class DeleteBrand extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Brand/DeleteBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class UpdateBrand extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Brand/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class GetAllBrand extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Brand/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export  {
    CreateBrand,
    DeleteBrand,
    UpdateBrand,
    GetAllBrand
};
