import WebServiceRequest from '../Api/WebServiceRequest'

class DeletePortfolio extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Portfolio/DeleteBy')
    }
    setParams(data){
       super.setRequestParam(data)
    }
}
class CreatePortfolio extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Portfolio/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UpdatePortfolio extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Portfolio/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class GetPortfolio extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Portfolio/GetBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class GetAllPortfolio extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Portfolio/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class DeleteMediaByPicURL extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/DeleteMediasBy')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
export  {
    DeletePortfolio,
    CreatePortfolio,
    UpdatePortfolio,
    GetAllPortfolio,
    GetPortfolio,
    DeleteMediaByPicURL
};
