import { render, staticRenderFns } from "./addPriceService.vue?vue&type=template&id=5d327bd6&scoped=true&"
import script from "./addPriceService.vue?vue&type=script&lang=js&"
export * from "./addPriceService.vue?vue&type=script&lang=js&"
import style0 from "./addPriceService.vue?vue&type=style&index=0&id=5d327bd6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d327bd6",
  null
  
)

export default component.exports