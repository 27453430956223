import WebServiceRequest from '../Api/WebServiceRequest'

class DeleteOption extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`ServiceOption/Delete?id=${data.id}`);
    }
}

class UpdateOption extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceOption/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateOption extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceOption/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class GetAllOptions extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ServiceOption/GetAll`)
    }

}
class DeleteOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`ServiceOptionValue/Delete?id=${data.id}`);
    }
}

class UpdateOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceOptionValue/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateOptionValue extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceOptionValue/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class GetAllOptionValuesByOptionId extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`ServiceOptionValue/GetValuesByOptionIdNew?optionId=${data.optionId}`)
    }
}



export {
    GetAllOptions,
    CreateOption,
    UpdateOption,
    DeleteOption,
    GetAllOptionValuesByOptionId,
    CreateOptionValue,
    UpdateOptionValue,
    DeleteOptionValue,
};
