import WebServiceRequest from '../Api/WebServiceRequest'

class MediaCreateFileRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Media/CreateFile`);
    }
    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class MediaGetAllRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Media/GetAll`);
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}

export {
    MediaCreateFileRequest,
    MediaGetAllRequest,
};
